import { render, staticRenderFns } from "./livelist.vue?vue&type=template&id=a476e326&scoped=true&"
import script from "./livelist.vue?vue&type=script&lang=js&"
export * from "./livelist.vue?vue&type=script&lang=js&"
import style0 from "./livelist.vue?vue&type=style&index=0&id=a476e326&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a476e326",
  null
  
)

export default component.exports