<template>
  <!--直播商品-多图-->
  <div class="detail-box">
    <div class="title">{{room.name}}</div>
    <div class="desc">主播：@{{room.anchor_name}}</div>
    <div class="img-box">
      <img class="left-img" mode="widthFix" :src="room.share_img">
      <div class="right">
        <img class="full-img" mode="widthFix" src="~images/index/haowu-cmh.png">
        <div class="more">
          <img class="full-img" mode="widthFix" src="~images/daoyou2.png">
          <div class="cover flexcenter">
            <span>更多</span>
            <span>商品</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'product5',
    props: {
      room:Object,
      bordernone: {
        type: Boolean,
      }
    },
    data() {
      return {
        value: 1,
      }
    },
    methods: {
      onChange(event) {
        console.log(event.detail);
      },
    }
  }
</script>

<style scoped>
  /* components/product5/product5.wxss */
  .full-img {
    display: block;
    width: 100%;
    border-radius: 8px;
  }
  .flexcenter {
    display: flex;
    align-items: center;
  }
  
  .detail-box {
    box-sizing: border-box;
    margin-bottom: 24px;
    padding: 32px 20px;
    width: 702px;
    background-color: #FFFFFF;
    border-radius: 20px;
  }
  .title {
    font-size: 28px;
    font-weight: bold;
    color: #191919;
  }
  .desc {
    margin: 20px 0 30px;
    font-size: 24px;
    color: #777777;
  }
  .img-box {
    display: flex;
    justify-content: space-between;
  }
  .left-img {
    width: 436px;
    border-radius: 8px;
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 220px;
  }
  .right .more {
    position: relative;
  }
  .right .more .cover {
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    font-size: 28px;
    font-weight: bold;
    color: #FFFFFF;
  }
</style>
