<template>
  <div class="page">
    <div class="nav flexcenter">
      <van-search v-model="searchValue" placeholder="搜索商品名称" />
    </div>
  
    <!--<text>秒杀</text>-->
    <div class="head flexcenter">
      <div :class="['item', {active: tab === 0}]" data-idx="0" @click="tab=0">
        回放
      </div>
      <div :class="['item', {active: tab === 1}]" data-idx="1" @click="tab=1">
        预告
      </div>
    </div>
  
    <div class="list-box">
      <product5
          v-for="(item, idx) in rooms"
          :key="item.id"
          :room="item"
      ></product5>
    </div>
  </div>
</template>

<script>
  import product5 from 'components/product5'
  
  export default {
    name: 'livelist',
    data() {
      return {
        searchValue: '',
        tab: 0,
        rooms:[]
      }
    },
    components: {
      product5,
    },
    created() {
      this.post(
        this.API.liveRoom,{}
      ).then(response => {
        this.rooms = response.data.rooms.room_info
      })
    },
    methods: {
      doa() {
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #F5F5F5;
  }
  /* pages/livelist/livelist.wxss */
  .van-search {
    width: 100%;
    padding-top: 10px!important;
  }
  .van-search__content {
    border-radius: 44px!important;
  }
  
  .nav {
    background-color: #FFFFFF;
  }

  .head {
    justify-content: center;
    height: 88px;
    background-color: #FFFFFF;
    font-size: 24px;
    border-bottom: 1px solid #EEEEEE;
    color: #777777;
  }
  .head .item {
    width: 237px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .head .active {
    position: relative;
    color: #191919;
    font-weight: bold;
  }
  .head .active:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -48px;
    width: 96px;
    height: 8px;
    border-radius: 50px 50px 0 0;
    background-color: #F8D414;
  }

  .list-box {
    padding: 24px;
  }

</style>
